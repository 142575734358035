import React from "react";
import { createUseStyles } from "react-jss";
import Colors from "Global/colors";
import { Flex, Typography, List } from "Component";
import { useTranslation } from "react-i18next";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
const useStyles = createUseStyles((theme) => ({
  downloadIcon: {
    cursor: "pointer",
    border: `1px solid ${Colors.BORDERCOLOR}`,
    maxWidth: 120,
    padding: theme.padding,
    textAlign: "center",
    color: "#3A3B3C",
    "&:hover": {
      backgroundColor: Colors.PRIMARY_LIGHT,
      color: Colors.WHITE,
    },
  },
  tncContainer: {
    margin: theme.margin * 4,
  },
  labelClass: {
    marginTop: theme?.margin * 2,
    marginBottom: theme?.margin,
    fontFamily: "OpenSans-Regular",
    textAlign: "left",
  },
  headingClass: {
    marginTop: theme?.margin,
    fontFamily: "OpenSans-Medium",
    textAlign: "left",
    textDecoration: "underline",
  },
  mainHeading: {
    fontFamily: "OpenSans-Bold",
    marginTop: theme?.margin,
    marginBottom: theme?.margin * 2,
    textAlign: "left",
  },
}));
const TermsAndCondition = () => {
  const classes = useStyles();
  const { tncContainer, labelClass, headingClass, mainHeading } = classes;
  const { t } = useTranslation();
  const printRef = React.useRef();
  /* istanbul ignore next */
  const printHandler = () => {
    var content = document.getElementById("printableArea");
    var pri = document.getElementById("ifmcontentstoprint").contentWindow;
    pri.document.open();
    pri.document.write(content.innerHTML);
    pri.document.close();
    pri.focus();
    pri.print();
  };
  /* istanbul ignore next */
  const handleDownloadPdf = async () => {
    const element = printRef.current;
    const canvas = await html2canvas(element);
    const doc = new jsPDF("p", "mm");
    const imgData = canvas.toDataURL("image/png");
    const imgWidth = 186.5;
    const pageHeight = 300.1;
    const imgHeight = (canvas.height * imgWidth) / canvas.width;
    let heightLeft = imgHeight;
    let position = 0;
    doc.addImage(
      imgData,
      "PNG",
      10,
      0,
      imgWidth,
      imgHeight + 25,
      undefined,
      "FAST"
    );
    heightLeft -= pageHeight;
    while (heightLeft >= 0) {
      position = heightLeft - imgHeight;
      doc.addPage();
      doc.addImage(
        imgData,
        "PNG",
        10,
        position,
        imgWidth,
        imgHeight + 25,
        undefined,
        "FAST"
      );
      heightLeft -= pageHeight;
    }
    doc.save("retailer_tnc.pdf");
  };

  return (
    <Flex direction="column" overrideClass={tncContainer}>
      <Flex justify="end">
        <Flex
          overrideClass={classes.downloadIcon}
          onClick={handleDownloadPdf}
          direction="row"
          align="center"
          justify="center"
          testID="downloadBtn_id"
        >
          {t("download")}
        </Flex>
        <Flex
          onClick={printHandler}
          overrideClass={classes.downloadIcon}
          direction="row"
          align="center"
          justify="center"
          testID="printBtn_id"
        >
          {t("print")}
        </Flex>
      </Flex>
      <iframe id="ifmcontentstoprint" style={{ display: "none" }}></iframe>

      <Flex direction="column" innerRef={printRef} id="printableArea">
        <Typography type="h4" overrideClass={mainHeading}>
          {t("terms_and_condition_heading")}{" "}
        </Typography>
        <Typography overrideClass={headingClass} type="h5">
          {t("terms_and_condition_Article_1")}
        </Typography>
        <Typography overrideClass={labelClass} color="#3A3B3C">
          {t("article_1_msg_1")}{" "}
          <a
            style={{ color: "blue" }}
            href="https://www.cofinacotedivoire.com"
            rel="noreferrer"
            target="_blank"
          >
            Cofina Côte d’Ivoire
          </a>
          {t("article_1_msg_1.1")}{" "}
          <a
            style={{ color: "blue" }}
            href="https://www.cofinacotedivoire.com"
            rel="noreferrer"
            target="_blank"
          >
            Cofina Côte d’Ivoire
          </a>
          {t("article_1_msg_1.2")}
        </Typography>
        <Typography overrideClass={labelClass} color="#3A3B3C">
          {t("article_1_msg_2")}{" "}
        </Typography>
        <Typography overrideClass={labelClass} color="#3A3B3C">
          c){" "}
          <a
            style={{ color: "blue" }}
            href="https://www.orange.ci/"
            rel="noreferrer"
            target="_blank"
          >
            ORANGE COTE D’IVOIRE
          </a>
          {t("article_1_msg_3")}
        </Typography>
        <Typography overrideClass={labelClass} color="#3A3B3C">
          {t("article_1_msg_4")}
        </Typography>
        <Typography overrideClass={labelClass} color="#3A3B3C">
          {t("article_1_msg_5")}
        </Typography>
        <Typography overrideClass={headingClass} type="h5">
          {t("terms_and_condition_Article_2")}
        </Typography>
        <Typography overrideClass={labelClass} color="#3A3B3C">
          {t("article_2_msg_1.1")}{" "}
          <a
            style={{ color: "blue" }}
            href="https://www.cofinacotedivoire.com"
            rel="noreferrer"
            target="_blank"
          >
            COFINA COTE D’IVOIRE
          </a>{" "}
          {t("article_2_msg_1.2")}
        </Typography>
        <Typography overrideClass={headingClass} type="h5">
          {t("terms_and_condition_Article_3")}
        </Typography>
        <Typography overrideClass={labelClass} color="#3A3B3C">
          {t("article_3_msg_1")}
        </Typography>
        <Typography overrideClass={headingClass} type="h5">
          {t("terms_and_condition_Article_4")}
        </Typography>
        <Typography overrideClass={labelClass} color="#3A3B3C">
          {t("article_4_msg_1")}
        </Typography>
        <Typography overrideClass={labelClass} color="#3A3B3C">
          {t("article_4_msg_2")}
        </Typography>
        <Typography overrideClass={headingClass} type="h5">
          {t("terms_and_condition_Article_5")}
        </Typography>
        <Typography color="#3A3B3C">{t("article_5_msg_1")}</Typography>
        <List
          listType="unordered"
          options={[
            {
              label: `3 ${t("month")}`,
              component: (item) => (
                <Typography color="#3A3B3C">{item.label}</Typography>
              ),
            },
            {
              label: `6 ${t("month")}`,
              component: (item) => (
                <Typography color="#3A3B3C">{item.label}</Typography>
              ),
            },
            {
              label: `9 ${t("month")}`,
              component: (item) => (
                <Typography color="#3A3B3C">{item.label}</Typography>
              ),
            },
          ]}
        />
        <Typography overrideClass={labelClass} color="#3A3B3C">
          {t("article_5_msg_2")}
        </Typography>
        <Typography overrideClass={labelClass} color="#3A3B3C">
          {t("article_5_msg_3")}
        </Typography>
        <Typography overrideClass={headingClass} type="h5">
          {t("terms_and_condition_Article_6")}
        </Typography>
        <Typography overrideClass={labelClass} color="#3A3B3C">
          {t("article_6_msg_1")}
        </Typography>
        <Typography overrideClass={headingClass} type="h5">
          {t("terms_and_condition_Article_7")}
        </Typography>
        <Typography overrideClass={labelClass} color="#3A3B3C">
          {t("article_7_msg_1")}
        </Typography>
        <Typography overrideClass={labelClass} color="#3A3B3C">
          {t("article_7_msg_2.1")} {t("article_7_msg_2.2")}
        </Typography>
        <Typography overrideClass={labelClass} color="#3A3B3C">
          {t("article_7_msg_2.3")}
        </Typography>
        <List
          listType="unordered"
          options={[
            {
              label: `${t("article_7_points_1.1")} ............(..........) ${t(
                "article_7_points_1.2"
              )}`,
              component: (item) => (
                <Typography color="#3A3B3C">{item.label}</Typography>
              ),
            },
            {
              label: `${t("article_7_points_2")}(..........)${t(
                "article_7_points_1.2"
              )}`,
              component: (item) => (
                <Typography color="#3A3B3C">{item.label}</Typography>
              ),
            },
            {
              label: `${t("article_7_points_3.1")} ............(..........) ${t(
                "article_7_points_3.2"
              )} ..............(............)${t("article_7_points_1.2")}`,
              component: (item) => (
                <Typography color="#3A3B3C">{item.label}</Typography>
              ),
            },
            {
              label: `${t("article_7_points_4")} ............(..........) ${t(
                "article_7_points_1.2"
              )}`,
              component: (item) => (
                <Typography color="#3A3B3C">{item.label}</Typography>
              ),
            },
          ]}
        />
        <Typography overrideClass={labelClass} color="#3A3B3C">
          {t("article_7_msg_3")}
        </Typography>
        <Typography overrideClass={labelClass} color="#3A3B3C">
          {t("article_7_msg_4")}
        </Typography>
        <Typography overrideClass={labelClass} color="#3A3B3C">
          {t("article_7_msg_5")}
        </Typography>
        <Typography overrideClass={labelClass} color="#3A3B3C">
          {t("article_7_msg_6")}
        </Typography>
        <Typography overrideClass={headingClass} type="h5">
          {t("terms_and_condition_Article_8")}
        </Typography>
        <Typography overrideClass={labelClass} color="#3A3B3C">
          {t("article_8_msg_1")}
        </Typography>
        <Typography color="#3A3B3C">{t("article_8_msg_2")}</Typography>
        <List
          listType="unordered"
          options={[
            {
              label: `${t("article_8_list_1")}`,
              component: (item) => (
                <Typography color="#3A3B3C">{item.label}</Typography>
              ),
            },
            {
              label: `${t("article_8_list_2")}`,
              component: (item) => (
                <Typography color="#3A3B3C">{item.label}</Typography>
              ),
            },
            {
              label: `${t("article_8_list_3")}`,
              component: (item) => (
                <Typography color="#3A3B3C">{item.label}</Typography>
              ),
            },
          ]}
        />
        <Typography overrideClass={labelClass} color="#3A3B3C">
          {t("article_8_msg_3")}
        </Typography>
        <Typography overrideClass={labelClass} color="#3A3B3C">
          {t("article_8_msg_4")}
        </Typography>
        <Typography overrideClass={headingClass} type="h5">
          {t("terms_and_condition_Article_9")}
        </Typography>
        <Flex direction="column">
          <Typography overrideClass={labelClass} color="#3A3B3C">
            {t("article_9_msg_1")}
          </Typography>
          <Typography overrideClass={labelClass} color="#3A3B3C">
            {t("article_9_msg_2")}
          </Typography>
          <Typography overrideClass={labelClass} color="#3A3B3C">
            {t("article_9_msg_3")}
          </Typography>
          <Typography overrideClass={labelClass} color="#3A3B3C">
            {t("article_9_msg_4")}
          </Typography>
          <Typography overrideClass={labelClass} color="#3A3B3C">
            {t("article_9_msg_5")}
          </Typography>
          <Typography overrideClass={labelClass} color="#3A3B3C">
            {t("article_9_msg_6")}
          </Typography>
        </Flex>
        <Typography overrideClass={headingClass} type="h5">
          {t("terms_and_condition_Article_10")}
        </Typography>
        <Flex direction="column">
          <Typography overrideClass={labelClass} color="#3A3B3C">
            {t("article_10_msg_1")}
          </Typography>
          <Typography overrideClass={labelClass} color="#3A3B3C">
            {t("article_10_msg_2")}
          </Typography>
          <Typography overrideClass={labelClass} color="#3A3B3C">
            {t("article_10_msg_3")}
          </Typography>
          <Typography overrideClass={labelClass} color="#3A3B3C">
            {t("article_10_msg_4")}
          </Typography>
          <Typography overrideClass={labelClass} color="#3A3B3C">
            {t("article_10_msg_5")}
          </Typography>
          <Typography overrideClass={labelClass} color="#3A3B3C">
            {t("article_10_msg_6")}
          </Typography>
          <Typography overrideClass={labelClass} color="#3A3B3C">
            {t("article_10_msg_7")}
          </Typography>
        </Flex>
        <Typography overrideClass={headingClass} type="h5">
          {t("terms_and_condition_Article_11")}
        </Typography>
        <Flex direction="column">
          <Typography overrideClass={labelClass} color="#3A3B3C">
            {t("article_11_msg_1")}
          </Typography>
          <Typography color="#3A3B3C">{t("article_11_msg_2")}</Typography>
          <List
            listType="unordered"
            options={[
              {
                label: `${t("article_11_list_1")}`,
                component: (item) => (
                  <Typography color="#3A3B3C">{item.label}</Typography>
                ),
              },
              {
                label: `${t("article_11_list_2")}`,
                component: (item) => (
                  <Typography color="#3A3B3C">{item.label}</Typography>
                ),
              },
              {
                label: `${t("article_11_list_3")}`,
                component: (item) => (
                  <Typography color="#3A3B3C">{item.label}</Typography>
                ),
              },
              {
                label: `${t("article_11_list_4")}`,
                component: (item) => (
                  <Typography color="#3A3B3C">{item.label}</Typography>
                ),
              },
            ]}
          />
        </Flex>
        <Typography overrideClass={headingClass} type="h5">
          {t("terms_and_condition_Article_12")}
        </Typography>
        <Flex direction="column">
          <Typography overrideClass={labelClass} color="#3A3B3C">
            {t("article_12_msg_1")}
          </Typography>
          <Typography overrideClass={labelClass} color="#3A3B3C">
            {t("article_12_msg_2")}
          </Typography>
          <Typography overrideClass={labelClass} color="#3A3B3C">
            {t("article_12_msg_3")}
          </Typography>
          <Typography overrideClass={labelClass} color="#3A3B3C">
            {t("article_12_msg_4")}
          </Typography>
        </Flex>
        <Typography overrideClass={headingClass} type="h5">
          {t("terms_and_condition_Article_13")}
        </Typography>
        <Flex direction="column">
          <Typography overrideClass={labelClass} color="#3A3B3C">
            {t("article_13_msg_1")}
          </Typography>
          <Typography overrideClass={labelClass} color="#3A3B3C">
            {t("article_13_msg_2")}
          </Typography>
          <Typography overrideClass={labelClass} color="#3A3B3C">
            {t("article_13_msg_3")}
          </Typography>
          <Typography overrideClass={labelClass} color="#3A3B3C">
            {t("article_13_msg_4")}
          </Typography>
        </Flex>
        <Typography overrideClass={headingClass} type="h5">
          {t("terms_and_condition_Article_14")}
        </Typography>
        <Flex direction="column">
          <Typography overrideClass={labelClass} color="#3A3B3C">
            {t("article_14_msg_1")}
          </Typography>
          <Typography overrideClass={labelClass} color="#3A3B3C">
            {t("article_14_msg_2")}
          </Typography>
        </Flex>
        <Typography overrideClass={headingClass} type="h5">
          {t("terms_and_condition_Article_15")}
        </Typography>
        <Flex direction="column">
          <Typography overrideClass={labelClass} color="#3A3B3C">
            {t("article_15_msg_1")}
          </Typography>
          <Typography overrideClass={labelClass} color="#3A3B3C">
            {t("article_15_msg_2")}
          </Typography>
        </Flex>
        <Typography overrideClass={headingClass} type="h5">
          {t("terms_and_condition_Article_16")}
        </Typography>
        <Flex direction="column">
          <Typography overrideClass={labelClass} color="#3A3B3C">
            {t("article_16_msg_1")}
          </Typography>
          <Typography overrideClass={labelClass} color="#3A3B3C">
            {t("article_16_msg_2")}
          </Typography>
        </Flex>
        <Typography overrideClass={headingClass} type="h5">
          {t("terms_and_condition_Article_17")}
        </Typography>
        <Flex direction="column">
          <Typography overrideClass={labelClass} color="#3A3B3C">
            {t("article_17_msg_1")}
          </Typography>
          <Typography overrideClass={labelClass} color="#3A3B3C">
            {t("article_17_msg_2")}
          </Typography>
          <Typography overrideClass={labelClass} color="#3A3B3C">
            {t("article_17_msg_3")}
          </Typography>
        </Flex>
        <Typography overrideClass={headingClass} type="h5">
          {t("terms_and_condition_Article_18")}
        </Typography>
        <Flex direction="column">
          <Typography overrideClass={labelClass} color="#3A3B3C">
            {t("article_18_msg_1")}
          </Typography>
          <Typography overrideClass={labelClass} color="#3A3B3C">
            {t("article_18_msg_2")}
          </Typography>
          <Typography overrideClass={labelClass} color="#3A3B3C">
            {t("article_18_msg_3")}
          </Typography>
          <Typography overrideClass={labelClass} color="#3A3B3C">
            {t("article_18_msg_4")}
          </Typography>
          <Typography overrideClass={labelClass} color="#3A3B3C">
            {t("article_18_msg_5")}
          </Typography>
          <Typography overrideClass={labelClass} color="#3A3B3C">
            {t("article_18_msg_6")}
          </Typography>
          <Typography overrideClass={labelClass} color="#3A3B3C">
            {t("article_18_msg_7")}
          </Typography>
          <Typography overrideClass={labelClass} color="#3A3B3C">
            {t("article_18_msg_8")}
          </Typography>
          <Typography overrideClass={labelClass} color="#3A3B3C">
            {t("article_18_msg_9")}
          </Typography>
          <Typography overrideClass={labelClass} color="#3A3B3C">
            {t("article_18_msg_10")}
          </Typography>
          <Typography overrideClass={labelClass} color="#3A3B3C">
            {t("article_18_msg_11")}
          </Typography>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default TermsAndCondition;
